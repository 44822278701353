import {
	Form,
	useFetcher,
	useLocation,
	useMatches,
	useSubmit,
} from '@remix-run/react'
import { useContext, useEffect, useRef, useState } from 'react'
import { Icon } from '#app/components/ui/icon'
import { Input, InputSlot } from '#app/components/ui/radixUiTheme/Input'
import { SearchContext } from '#app/root'
import { cn, useDebounce } from '#app/utils/misc'
import { Button } from '../../ui/radixUiTheme/Button'
import { IconButton } from '../../ui/radixUiTheme/IconButton'

type LatLongType = {
	lat: number
	long: number
}

type SearchType =
	| 'none'
	| 'location'
	| 'user'
	| 'chat'
	| 'reactDevtools'
	| 'document'
	| 'equipment'
	| 'businessNode'
	| 'customer'
	| 'barterOffer'

type SearchInfoReturnType = {
	searchType: SearchType
	showSearch: boolean
	placeHolder: string
	disableInputs: boolean
	showSuggestions: boolean
	suggestionsLoaderUrl?: string
	formAction?: string
	correctSuggestionText?: (suggestion: any) => string
}

function useSearchInfoBasedOnPage(): SearchInfoReturnType {
	const matches = useMatches()
	// console.log('matches', matches)
	const isOnHeroPage = matches.find(m => m.id === 'routes/index')
	const isOnMapPage = matches.find(m => m.id === 'routes/map+/route')
	const isOnAllChatsPage = matches.find(
		m => m.id === 'routes/chats+/all+/route',
	)
	const isOnUsersPage = matches.find(m => m.id === 'routes/users+/all+/index')
	const isOnDocumentsPage = matches.find(
		m => m.id === 'routes/chats+/$chatId+/documentEditor+/index',
	)

	const isOnEquipmentPage = matches.find(
		m => m.id === 'routes/users+/$username+/equipment+/index',
	)

	const isOnBusinessNodesPage = matches.find(m =>
		m.id.includes('routes/users+/$username+/businessNodes+'),
	)

	const isOnCustomerPage = matches.find(
		m => m.id === 'routes/users+/$username+/customers+/index',
	)

	const isOnBarterListPage = matches.find(
		m => m.id === 'routes/users+/$username+/offers+/index',
	)

	if (isOnHeroPage || isOnMapPage) {
		return {
			searchType: 'location',
			showSearch: true,
			disableInputs: false,
			formAction: '/map',
			placeHolder: 'Select a city to find your next customer...',
			showSuggestions: true,
			suggestionsLoaderUrl: '/api/searchSuggestions/cities',
			correctSuggestionText: (suggestion: any) =>
				`${suggestion.city}, ${suggestion.stateId}`,
		}
	} else if (isOnAllChatsPage) {
		return {
			searchType: 'chat',
			showSearch: true,
			disableInputs: false,
			formAction: '/chats/all',
			placeHolder: 'Search for a chat...',
			showSuggestions: true,
			suggestionsLoaderUrl: '/api/searchSuggestions/chatPage',
			correctSuggestionText: (suggestion: any) =>
				`Chat with: ${suggestion.receiverUser.username}`,
		}
	} else if (isOnUsersPage) {
		return {
			searchType: 'user',
			showSearch: true,
			disableInputs: false,
			formAction: '/users/all',
			placeHolder: 'Search for a user...',
			showSuggestions: true,
			suggestionsLoaderUrl: '/api/searchSuggestions/usersPage',
			correctSuggestionText: (suggestion: any) =>
				`${suggestion.username} - ${suggestion.fullName}`,
		}
	} else if (isOnEquipmentPage) {
		return {
			searchType: 'equipment',
			showSearch: true,
			formAction: `/users/${isOnEquipmentPage.params.username}/equipment`,
			placeHolder: 'Search for equipment...',
			disableInputs: false,
			showSuggestions: true,
			suggestionsLoaderUrl: '/api/searchSuggestions/equipmentPage',
			correctSuggestionText: (suggestion: any) => `${suggestion.name}`,
		}
	} else if (isOnDocumentsPage) {
		return {
			searchType: 'document',
			showSearch: true,
			disableInputs: false,
			formAction: `/chats/${isOnDocumentsPage.params.chatId}/documentEditor`,
			placeHolder: 'Search for a document...',
			showSuggestions: true,
			suggestionsLoaderUrl: '/api/searchSuggestions/documentsPage',
			correctSuggestionText: (suggestion: any) => `${suggestion.name}`,
		}
	} else if (isOnBusinessNodesPage) {
		return {
			searchType: 'businessNode',
			showSearch: true,
			formAction: `/users/${isOnBusinessNodesPage.params.username}/businessNodes`,
			placeHolder: 'Search your business nodes...',
			disableInputs: false,
			showSuggestions: true,
			suggestionsLoaderUrl: '/api/searchSuggestions/businessNodesPage',
			correctSuggestionText: (suggestion: any) => `${suggestion.nickName}`,
		}
	} else if (isOnCustomerPage) {
		return {
			searchType: 'customer',
			showSearch: true,
			formAction: `/users/${isOnCustomerPage.params.username}/customers`,
			placeHolder: 'Search your customers...',
			disableInputs: false,
			showSuggestions: true,
			suggestionsLoaderUrl: '/api/searchSuggestions/customersPage',
			correctSuggestionText: (suggestion: any) => `${suggestion.fullName}`,
		}
	} else if (isOnBarterListPage) {
		return {
			searchType: 'barterOffer',
			showSearch: true,
			formAction: `/users/${isOnBarterListPage.params.username}/offers`,
			placeHolder: 'Search your barter offers...',
			disableInputs: false,
			showSuggestions: true,
			suggestionsLoaderUrl: '/api/searchSuggestions/barterOfferListPage',
			correctSuggestionText: (suggestion: any) =>
				`${suggestion.senderUser.username} -> ${suggestion.receiverUser.username}`,
		}
	} else {
		return {
			searchType: 'none',
			showSearch: false,
			placeHolder: 'Search...',
			disableInputs: false,
			showSuggestions: false,
		}
	}
}

export function SearchBar({ className }: { className?: string }) {
	const suggestionFetcher = useFetcher()
	const submit = useSubmit()
	const location = useLocation()
	const previousLocation = useRef(location)

	const { inputValue, setInputValue } = useContext(SearchContext)
	const [showSuggestionsDropdown, setShowSuggestionsDropdown] = useState(false)

	// Page specific states
	const [suggestionLatLong, setSuggestionLatLong] =
		useState<LatLongType | null>(null)
	const [documentId, setDocumentId] = useState<string | null>(null)
	const [barterOfferId, setBarterOfferId] = useState<string | null>(null)

	const details = useSearchInfoBasedOnPage()

	// Clears the input field and hides the suggestions dropdown when the input is empty
	useEffect(() => {
		if (inputValue === '' && location.pathname !== '/map') {
			setShowSuggestionsDropdown(false)
		}
	}, [details.formAction, inputValue, location.pathname, submit])

	// Clears the input field and hides the suggestions dropdown when the page changes
	useEffect(() => {
		// Store the previous location on each render
		const prevPathname = previousLocation.current.pathname
		previousLocation.current = location // Update the ref to the current location

		// Check for special route transitions
		const isSpecialRouteTransition =
			(prevPathname === '/' && location.pathname === '/map') ||
			(prevPathname === '/map' && location.pathname === '/')

		// Determine if it's the same page with query params
		const isSamePageWithQuery =
			prevPathname === location.pathname && location.search

		// Clear input except in specified scenarios
		if (!isSamePageWithQuery && !isSpecialRouteTransition) {
			setInputValue('')
			setShowSuggestionsDropdown(false)
		}
	}, [location, setInputValue])

	const loadSuggestions = useDebounce((value: string) => {
		console.log('Loading suggestions', details.suggestionsLoaderUrl, value)
		suggestionFetcher.load(`${details.suggestionsLoaderUrl}?filter=${value}`) // Fetch suggestions
		setShowSuggestionsDropdown(true)
	}, 400)

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value
		setInputValue(value)

		if (
			value !== '' &&
			details.showSuggestions &&
			details.suggestionsLoaderUrl
		) {
			loadSuggestions(value)
		}
	}

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		let filterObject: any = { filter: inputValue }

		if (details.searchType === 'location') {
			filterObject = {
				...filterObject,
				lat: suggestionLatLong?.lat.toString() || '',
				long: suggestionLatLong?.long.toString() || '',
			}
		} else if (details.searchType === 'document') {
			filterObject = { ...filterObject, documentId: documentId }
		} else if (details.searchType === 'barterOffer') {
			filterObject = { ...filterObject, barterOfferId: barterOfferId }
		}

		submit(filterObject, { method: 'get', action: details.formAction })
	}

	function ClearInputButton() {
		const onClearInputClick = () => {
			setInputValue('')
			setSuggestionLatLong(null)
			submit({}, { method: 'get', action: details.formAction })
		}

		return (
			<IconButton size={'1'} onClick={onClearInputClick}>
				<Icon className="z-20 rounded-full" name={'cross-1'} />
			</IconButton>
		)
	}

	function SuggestionDropdown() {
		const data = suggestionFetcher.data as { suggestions: any }

		if (data === undefined) {
			return null
		}

		function onUseMyLocationSuggestionClick() {
			navigator.geolocation.getCurrentPosition(position => {
				// TODO: this needs to show the City, State as the input value
				setInputValue('My Location')
				setSuggestionLatLong({
					lat: position.coords.latitude,
					long: position.coords.longitude,
				})
				setShowSuggestionsDropdown(false)
			})
		}

		// TODO: not sure if there is a way to move this to details obj
		function onSuggestionClick(searchType: SearchType, suggestion: any) {
			if (searchType === 'location') {
				setInputValue(`${suggestion.city}, ${suggestion.stateId}`)
				setSuggestionLatLong({
					lat: suggestion.lat,
					long: suggestion.long,
				})
			} else if (searchType === 'chat') {
				setInputValue(`${suggestion.receiverUser.username}`)
			} else if (searchType === 'document') {
				setInputValue(`${suggestion.name}`)
				setDocumentId(suggestion.id)
			} else if (searchType === 'user') {
				setInputValue(`${suggestion.username}`)
			} else if (searchType === 'equipment') {
				setInputValue(`${suggestion.name}`)
			} else if (searchType === 'businessNode') {
				setInputValue(`${suggestion.nickName}`)
			} else if (searchType === 'customer') {
				setInputValue(`${suggestion.fullNmae}`)
			} else if (searchType === 'barterOffer') {
				setInputValue(
					`${suggestion.senderUser.username} -> ${suggestion.receiverUser.username}`,
				)
				setBarterOfferId(suggestion.id)
			} else {
				setInputValue('SOME VALUE')
			}
		}

		return (
			// <div className="absolute top-10 z-10 flex max-h-60 w-full flex-col overflow-auto rounded-md bg-white shadow-md">
			<div className="absolute top-14 z-10 flex max-h-60 w-full flex-col overflow-auto rounded-md shadow-md">
				{details.searchType === 'location' && (
					<Button
						className="justify-start p-2 hover:bg-[var(--accent-5)]"
						// OnMouseDown is used to prevent the input from losing focus
						// so we can click on suggestion before closing the dropdown
						onMouseDown={e => e.preventDefault()}
						onClick={e => {
							onUseMyLocationSuggestionClick()
							setShowSuggestionsDropdown(false)
						}}
					>
						Use My Location
					</Button>
				)}
				{data !== undefined && data.suggestions?.length === 0 ? (
					<div className="h-10 rounded-md px-4 py-2 text-sm font-medium text-black">
						There are no suggestions available...
					</div>
				) : (
					data.suggestions?.map((suggestion: any) => (
						<Button
							key={suggestion.id}
							className="justify-start p-2 hover:bg-[var(--accent-5)]"
							// OnMouseDown is used to prevent the input from losing focus
							// so we can click on suggestion before closing the dropdown
							onMouseDown={e => e.preventDefault()}
							onClick={e => {
								onSuggestionClick(details.searchType, suggestion)
								setShowSuggestionsDropdown(false)
							}}
						>
							<div className="truncate">
								{details.correctSuggestionText &&
									details.correctSuggestionText(suggestion)}
							</div>
						</Button>
					))
				)}
			</div>
		)
	}

	return (
		<Form className="flex w-full grow" onSubmit={onSubmit}>
			{details.showSearch && (
				<>
					<div className="relative flex grow">
						<Input
							size={'3'}
							type="search"
							name="search"
							value={inputValue}
							placeholder={details.placeHolder}
							className={cn('m-0 w-full', className)}
							autoFocus={false}
							autoComplete="off"
							onChange={onInputChange}
							onBlur={() => {
								setShowSuggestionsDropdown(false)
							}}
							onFocus={() => {
								if (inputValue) {
									setShowSuggestionsDropdown(true)
								}
							}}
						>
							<InputSlot>
								<Icon name="magnifying-glass" size="3" />
							</InputSlot>
							<InputSlot side="right">
								{inputValue && <ClearInputButton />}
								<Button
									size={'1'}
									type={'submit'}
									disabled={inputValue === '' || details.disableInputs}
									className="items-center justify-center px-1"
								>
									Search
								</Button>
							</InputSlot>
						</Input>
						{showSuggestionsDropdown && <SuggestionDropdown />}
					</div>
				</>
			)}
		</Form>
	)
}
