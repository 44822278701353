import { Link, useLocation } from '@remix-run/react'
import { useOptionalUser } from '#app/utils/user'
import { Button } from '../../ui/radixUiTheme/Button'
import Logo from '../logo'
import { SearchBar } from './search-bar'
import { UserDropdown } from './userDropdown'

function DropdownOrLogin() {
	const user = useOptionalUser()

	return user ? (
		<UserDropdown />
	) : (
		<div className="flex flex-row gap-2">
			<Button asChild size={'2'}>
				<Link to="/login">Log In</Link>
			</Button>
			<Button asChild size={'2'} variant="outline">
				<Link to="/signup">Sign Up</Link>
			</Button>
		</div>
	)
}

export default function Header() {
	const location = useLocation()
	// const pathsWithoutTopSearchBar = ['/', '/login', '/signup']
	// const pathsWithoutLoginButton = ['/login', '/signup']

	// const isOnHeroPage = pathsWithoutTopSearchBar.includes(location.pathname)
	// const isOnLoginPage = pathsWithoutLoginButton.includes(location.pathname)

	const pathsWithLogo = [
		'/',
		'/login',
		'/signup',
		'/signup-email',
		'/onboarding',
		'/forgot-password',
		'/verify',
		'/reset-password',
	]
	const showLogo = pathsWithLogo.includes(location.pathname)

	const pathsWithoutTopSearchBar = pathsWithLogo

	const showSearchBar = !pathsWithoutTopSearchBar.includes(location.pathname)

	const pathsWithDropdownOrLogin = pathsWithLogo

	const showDropdownOrLogin = pathsWithDropdownOrLogin.includes(
		location.pathname,
	)

	return (
		<header className="m-2 flex max-w-screen-lg grow">
			<nav className="flex grow flex-row">
				<div className="flex flex-1 items-center justify-between">
					{showLogo && <Logo />}
					{showSearchBar && <SearchBar />}
					{showDropdownOrLogin && <DropdownOrLogin />}
				</div>
			</nav>
		</header>
	)
}
