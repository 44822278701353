import { useSubmit, Link, Form } from '@remix-run/react'
import { useRef } from 'react'
import { getUserImgSrc } from '#app/utils/misc'
import { useUser } from '#app/utils/user'
import { Icon } from '../../ui/icon'
import { Button } from '../../ui/radixUiTheme/Button'
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuItem,
} from '../../ui/radixUiTheme/DropdownMenu'
import { Separator } from '../../ui/radixUiTheme/Separator'

export function UserDropdown() {
	const user = useUser()
	const submit = useSubmit()
	const formRef = useRef<HTMLFormElement>(null)

	return (
		<DropdownMenu>
			<DropdownMenuTrigger>
				<Button asChild size={'3'} variant="soft">
					<Link
						to={`/users/${user.username}`}
						// this is for progressive enhancement
						onClick={e => e.preventDefault()}
						className="flex items-center gap-2"
					>
						<img
							className="h-8 w-8 rounded-full object-cover"
							alt={user.fullName ?? user.username}
							src={getUserImgSrc(user.image?.id)}
						/>
						<span className="text-body-sm font-bold">
							{user.fullName ?? user.username}
						</span>
					</Link>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent sideOffset={8} align="end">
				<DropdownMenuItem>
					<Link prefetch="intent" to={`/map`} className="w-full">
						<Icon className="text-body-md" name="globe">
							Map
						</Icon>
					</Link>
				</DropdownMenuItem>
				<Separator />
				<DropdownMenuItem>
					<Link
						prefetch="intent"
						to={`/users/${user.username}`}
						className="w-full"
					>
						<Icon className="text-body-md" name="avatar">
							Profile
						</Icon>
					</Link>
				</DropdownMenuItem>
				<DropdownMenuItem>
					<Link
						prefetch="intent"
						to={`/users/${user.username}/businessNodes`}
						className="w-full"
					>
						<Icon className="text-body-md" name="locations-route">
							Business Nodes
						</Icon>
					</Link>
				</DropdownMenuItem>
				<DropdownMenuItem>
					<Link
						prefetch="intent"
						to={`/users/${user.username}/customers`}
						className="w-full"
					>
						<Icon className="text-body-md" name="connected-users">
							Customers
						</Icon>
					</Link>
				</DropdownMenuItem>
				<Separator />
				<DropdownMenuItem>
					<Link
						prefetch="intent"
						to={`/users/${user.username}/notifications`}
						className="w-full"
					>
						<Icon className="text-body-md" name="bell">
							Notifications
						</Icon>
					</Link>
				</DropdownMenuItem>
				<DropdownMenuItem>
					<Link prefetch="intent" to={`/chats/all`} className="w-full">
						<Icon className="text-body-md" name="chat">
							All Chats
						</Icon>
					</Link>
				</DropdownMenuItem>
				<Separator />
				<DropdownMenuItem
					// this prevents the menu from closing before the form submission is completed
					onSelect={event => {
						event.preventDefault()
						submit(formRef.current)
					}}
				>
					<Form action="/logout" method="POST" ref={formRef}>
						<Icon className="text-body-md" name="exit">
							<button type="submit">Log Out</button>
						</Icon>
					</Form>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
