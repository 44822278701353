import { parseWithZod } from '@conform-to/zod';
import { invariantResponse } from '@epic-web/invariant';
import { Theme as RadixTheme } from '@radix-ui/themes';
import radixUiCss from '@radix-ui/themes/styles.css';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  json,
  type HeadersFunction,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
  type ActionFunctionArgs } from
'@remix-run/node';
import {
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation } from
'@remix-run/react';
import { withSentry } from '@sentry/remix';
import { StrictMode, createContext, useState } from 'react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import Header from './components/headerFooter/header/header.tsx';
import { EpicProgress } from './components/progress-bar.tsx';
import { useAccentColor } from './components/sideBarNavigation/accentColorSwitch';
import MainSideBarNavigation from './components/sideBarNavigation/sideBarNav.tsx';
import {
  ThemeFormSchema,
  useTheme } from
'./components/sideBarNavigation/themeSwitch';
import { useToast } from './components/toaster.tsx';
import { EpicToaster } from './components/ui/epicToaster.tsx';
import { Icon, href as iconsHref } from './components/ui/icon.tsx';
import { IconButton } from './components/ui/radixUiTheme/IconButton';
import { APP_DESCRIPTION, APP_NAME } from './constants';
import { getUnreadNotificationsCount } from './routes/users+/$username+/notifications+/notificationsUtilities.server';
import tailwindStyleSheetUrl from './styles/tailwind.css';
import { getUserId, logout } from './utils/auth.server.ts';
import { ClientHintCheck, getHints } from './utils/client-hints.tsx';
import { prisma } from './utils/db.server.ts';
import { getEnv } from './utils/env.server.ts';
import { honeypot } from './utils/honeypot.server.ts';
import { combineHeaders, getDomainUrl } from './utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import {
  AccentColorFormSchema,
  type AccentColorTheme } from
'./utils/theme/accentColors';
import {
  getAccentColorTheme,
  setAccentColorTheme } from
'./utils/theme/accentColorTheme.server';
import { getTheme, setTheme, type Theme } from './utils/theme/theme.server';
import { makeTimings, time } from './utils/timing.server.ts';
import { getToast } from './utils/toast.server.ts';

// Create a context for the search input
export const SearchContext = createContext({
  inputValue: '',
  setInputValue: (value: string) => {}
});

// Context provider component
function SearchProvider({ children }: {children: React.ReactNode;}) {
  const [inputValue, setInputValue] = useState('');
  return (
    <SearchContext.Provider value={{ inputValue, setInputValue }}>
			{children}
		</SearchContext.Provider>);

}

export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: 'preload', href: iconsHref, as: 'image' },
  // Preload CSS as a resource to avoid render blocking
  { rel: 'preload', href: radixUiCss, as: 'style' },
  { rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
  cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
  { rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
  {
    rel: 'alternate icon',
    type: 'image/png',
    href: '/favicons/favicon-32x32.png'
  },
  { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  } as const, // necessary to make typescript happy
  //These should match the css preloads above to avoid css as render blocking resource
  { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
  { rel: 'stylesheet', href: radixUiCss },
  { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: data ? APP_NAME : `Error | ${APP_NAME}` },
  {
    name: 'description',
    content: APP_DESCRIPTION
  }];

};

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
  return headers;
};

export async function loader({
  request,
  context: loadContext
}: LoaderFunctionArgs) {
  const timings = makeTimings('root loader');
  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root'
  });

  const user = userId ?
  await time(
    () =>
    prisma.user.findUniqueOrThrow({
      select: {
        id: true,
        firstName: true,
        lastName: true,
        username: true,
        fullName: true,
        image: { select: { id: true } },
        roles: {
          select: {
            name: true,
            permissions: {
              select: { entity: true, action: true, access: true }
            }
          }
        }
      },
      where: { id: userId }
    }),
    { timings, type: 'find user', desc: 'find user in root' }
  ) :
  null;

  if (userId && !user) {
    console.info('something weird happened');
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await logout({ request, redirectTo: '/' });
  }
  const { toast, headers: toastHeaders } = await getToast(request);
  const honeyProps = honeypot.getInputProps();

  let unreadNotificationCount = 0;
  if (user) {
    unreadNotificationCount = await getUnreadNotificationsCount(user.id);
  }

  return json(
    {
      user,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request),
          accentColor: getAccentColorTheme(request)
        }
      },
      ENV: getEnv(),
      toast,
      honeyProps,
      nonce: loadContext.cspNonce as string,
      unreadNotificationCount
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        toastHeaders
      )
    }
  );
}

// NOTE: used by themePicker and accentColorPicker from sideBarNav.tsx
export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const _action = formData.get('_action');

  if (_action === 'theme') {
    const submission = parseWithZod(formData, {
      schema: ThemeFormSchema
    });
    invariantResponse(submission.status === 'success', 'Invalid theme received');
    const { theme } = submission.value;
    const responseInit = {
      headers: { 'set-cookie': setTheme(theme) }
    };
    return json({ result: submission.reply() }, responseInit);
  } else if (_action === 'accentColor') {
    const submission = parseWithZod(formData, {
      schema: AccentColorFormSchema
    });
    invariantResponse(
      submission.status === 'success',
      'Invalid accent color received'
    );
    const { accentColor } = submission.value;
    const responseInit = {
      headers: { 'set-cookie': setAccentColorTheme(accentColor) }
    };
    return json({ result: submission.reply() }, responseInit);
  }

  // Handle other actions or return an error
  return json({ error: 'Invalid action' }, { status: 400 });
}

function Document({
  children,
  nonce,
  theme = 'light',
  accentColor = 'sky',
  env = {}






}: {children: React.ReactNode;nonce: string;theme?: Theme;accentColor?: AccentColorTheme;env?: Record<string, string>;}) {
  return (
    <html lang="en" className="m-0 h-screen w-screen">
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1" />

				<script
          defer
          src="https://umami-website-analytics.fly.dev/script.js"
          data-website-id="bd9f0c71-7d60-4467-8bb3-92c14e4446a3">
        </script>
				<Links />
			</head>
			<body className="m-0 h-screen w-screen">
				<SearchProvider>
					<RadixTheme
            id="theme-provider"
            appearance={theme}
            accentColor={accentColor}
            radius="medium"
            grayColor="sand"
            panelBackground="translucent">

						<div className="h-screen w-screen bg-[var(--accent-1)]">
							{children}
						</div>
					</RadixTheme>
				</SearchProvider>
				<script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<LiveReload nonce={nonce} />
			</body>
		</html>);

}

function App() {
  const data = useLoaderData<typeof loader>();
  const theme = useTheme();
  const accentColor = useAccentColor();
  const location = useLocation();

  const userLoggedIn = data.user !== null;

  useToast(data.toast);

  return (
    <Document
      nonce={data.nonce}
      theme={theme}
      accentColor={accentColor}
      env={data.ENV}>

			<EpicProgress />
			<div className="flex h-screen w-screen min-w-[370px]">
				<EpicToaster theme={theme} closeButton />

				<MainSideBarNavigation />

				<div
          data-testid="content-container"
          className="container mb-2 flex max-w-screen-lg flex-col">

					<div data-testid="header" className="flex">
						<Header />
					</div>

					<div
            data-testid="main-outlet"
            className="mb-2 flex grow overflow-auto">

						<Outlet />
					</div>
				</div>
			</div>

			{!['/feedback/editor'].includes(location.pathname) && userLoggedIn &&
      <IconButton
        tooltipText="Submit Feedback"
        className="fixed bottom-4 right-4 z-50 h-7 w-7 rounded-full"
        asChild>

					<Link to="/feedback/editor" state={{ prevUrl: location.pathname }}>
						<Icon size="1" name={'bug'} />
					</Link>
				</IconButton>
      }
		</Document>);

}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <HoneypotProvider {...data.honeyProps}>
			<StrictMode>
				<App />
			</StrictMode>
		</HoneypotProvider>);

}

export default withSentry(AppWithProviders);

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>);

}